<template>
  <div>
    <div class='mb8'>
      <el-form size='mini' inline>
        <el-form-item>
          <el-input placeholder='对接人' v-model='searchCondition.picker' clearable @change='handleQuery'></el-input>
        </el-form-item>
        <el-form-item>
          <el-select placeholder='选择反馈状态' v-model='searchCondition.is_feed' clearable @change='handleQuery'>
            <el-option v-for='(item ,index) in isFeedMap'
                       :key='index'
                       :label='item.label'
                       :value='item.val'>
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-input v-model='searchCondition.store_link' clearable placeholder='反向店铺名称搜索'
                    @change='handleQuery'></el-input>
        </el-form-item>
        <el-form-item>
          <el-button icon='el-icon-search' type='primary' @click='handleQuery' :loading='loadingStatus'>查询</el-button>
        </el-form-item>
      </el-form>
      <el-row :gutter='10'>
        <el-col :span='1.5'>
          <el-button icon='el-icon-plus' type='primary' @click='handleAdd'
                     v-if='userPermissions.indexOf("lb_dress_demand_add")>-1'>添加需求
          </el-button>
        </el-col>
        <el-col :span='1.5'>
          <el-button icon='el-icon-upload' type='primary' @click='handleImport'
                     v-if='userPermissions.indexOf("lb_dress_demand_add")>-1'>导入
          </el-button>
        </el-col>
        <el-col :span='1.5'>
          <el-button icon='el-icon-upload' type='danger' @click='handleBatchDel'
                     v-if='userPermissions.indexOf("lb_dress_demand_add")>-1' :disabled='multiple'>批量删除
          </el-button>
        </el-col>
        <el-col :span='1.5'>
          <export-btn :can-export='userPermissions.indexOf("lb_dress_demand_export")>-1' lintTitle='导出'
                      ref='refExportLink'
                      @export='exportDemandExcel("refExportLink")'></export-btn>
        </el-col>
      </el-row>
    </div>
    <div class='default-table'>
      <el-table :data='dataList' highlight-current-row max-height='500' row-key='id'
                @selection-change='handleSelectRows' border>
        <el-table-column type='selection' :reserve-selection='true' width='55'></el-table-column>
        <el-table-column label='序号' type='index' align='center' min-width='60'></el-table-column>
        <el-table-column label='反向需求' prop='demand' align='center' min-width='100'></el-table-column>
        <el-table-column label='客单价' prop='price' align='center' min-width='100'></el-table-column>
        <el-table-column label='反向店铺' align='center' prop='store_link'  min-width='150'/>
        <el-table-column label='找寻款式数' prop='style_num' align='center' width='100'>
        </el-table-column>
        <el-table-column label='参考图片1' prop='title' align='center' width='100'>
          <template v-slot='{row:{refer_cover_url_1}}'>
            <el-image :src='refer_cover_url_1' style='width: 60px;height: 60px;' width='60px' fit='scale-down'
                      :preview-src-list='[refer_cover_url_1]'>
              <div slot='error' class='image-slot' style='display: flex;align-items: center;justify-content: center;'>
                <i class='el-icon-picture-outline'></i>
              </div>
            </el-image>
          </template>
        </el-table-column>
        <el-table-column label='参考图片2' prop='title' align='center' width='100'>
          <template v-slot='{row:{refer_cover_url_2}}'>
            <el-image :src='refer_cover_url_2' style='width: 60px;height: 60px;' width='60px' fit='scale-down'
                      :preview-src-list='[refer_cover_url_2]'>
              <div slot='error' class='image-slot' style='display: flex;align-items: center;justify-content: center;'>
                <i class='el-icon-picture-outline'></i>
              </div>
            </el-image>
          </template>
        </el-table-column>
        <el-table-column label='参考图片3' prop='title' align='center' width='100'>
          <template v-slot='{row:{refer_cover_url_3}}'>
            <el-image :src='refer_cover_url_3' style='width: 60px;height: 60px;' width='60px' fit='scale-down'
                      :preview-src-list='[refer_cover_url_3]'>
              <div slot='error' class='image-slot' style='display: flex;align-items: center;justify-content: center;'>
                <i class='el-icon-picture-outline'></i>
              </div>
            </el-image>
          </template>
        </el-table-column>
        <el-table-column label='招商对接人' prop='picker' align='center' min-width='140'>
          <editable-cell
              v-if='userPermissions.indexOf("lb_dress_demand_picker")>-1' slot-scope='{row,column}'
              :show-input='row.editMode' maxlength='100' placeholder='最多100个字符' v-model='row[column.property]'
              :is-selected='false' @change='editCellVal(row,column.property)' :is-input-number='false'>
              <span slot='content'>
              <span>{{ row[column.property] }}</span>
               <i class='el-icon-edit default-edit-icon'></i>
               </span>
          </editable-cell>
        </el-table-column>
        <el-table-column label='招商反馈' prop='is_feed' align='center' min-width='480'>
          <el-table-column label='反馈状态' prop='is_feed' align='center' min-width='260'>
            <template slot-scope='{row,column}'>
              <el-radio-group v-model='row.is_feed' @change='editCellVal(row,column.property)' size='mini'>
                <el-radio :label='""'>未设置</el-radio>
                <el-radio :label='`Y`'>已反馈</el-radio>
                <el-radio :label='`N`'>未反馈</el-radio>
              </el-radio-group>
            </template>
          </el-table-column>
          <el-table-column label='反馈内容' prop='feedback' align='center' min-width='200'>
            <editable-cell v-if='userPermissions.indexOf("lb_dress_demand_feedback")>-1' slot-scope='{row,column}'
                           :show-input='row.editMode'
                           maxlength='100' placeholder='最多200个字符'
                           v-model='row[column.property]' :is-selected='false'
                           @change='editCellVal(row,column.property)' :is-input-number='false'>
                                    <span slot='content'>
                                      <span>{{ row[column.property] }}</span>
                                      <i class='el-icon-edit default-edit-icon'></i>
                                    </span>
            </editable-cell>
          </el-table-column>
        </el-table-column>
        <el-table-column label='创建时间' prop='created_at' align='center' show-overflow-tooltip
                         min-width='100'></el-table-column>
        <el-table-column label='操作' align='center' min-width='120' fixed='right'>
          <template slot-scope='{row}' v-if='userPermissions.indexOf("lb_dress_demand_add")>-1'>
            <el-button type='text' @click='handleEdit(row)'>修改 <span class='el-icon-edit-outline'></span></el-button>
            &nbsp;
            <el-dropdown>
              <span class='el-dropdown-link'>
                更多<i class='el-icon-arrow-down el-icon--right'></i>
              </span>
              <el-dropdown-menu slot='dropdown'>
                <el-dropdown-item>
                  <el-button type='text' icon='el-icon-copy-document' @click='handleCopy(row)'>复制</el-button>
                </el-dropdown-item>
                <el-dropdown-item divided>
                  <el-button type='text' icon='el-icon-delete' @click='handleDelete(row)'>删除</el-button>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <div>
        <!--    渲染分页-->
        <m-pagination :total.sync='total' :limit.sync='pagingData.page_size' :page.sync='pagingData.current_page'
                      @pagination='getList'/>
      </div>
    </div>
    <!--    新增或修改-->
    <el-dialog :visible.sync='open' @close='handleQuery' center width='800px' :title='dialogTitle'>
      <div style='width: 700px;margin: auto;'>
        <el-form size='small' :model='formData' ref='form' label-width='120px' label-position='right'
                 class='demand-form'>
          <el-form-item label='反向需求' class='form-item-all-line'>
            <el-input v-model='formData.demand' type='textarea' :rows='3'
                      placeholder='需明确：风格+长度+主推主色' maxlength='500' show-word-limit
                      clearable></el-input>
          </el-form-item>
          <el-form-item label='客单价' class='form-item-all-line'>
            <el-input v-model='formData.price'
                      show-word-limit
                      clearable></el-input>
          </el-form-item>
          <el-form-item label='反向店铺' class='form-item-all-line'>
            <el-input v-model='formData.store_link' type='textarea' :rows='3'
                      placeholder='所播平台 有店铺的链接（上播抖音 必须在抖音上有店铺）' maxlength='500' show-word-limit
                      clearable></el-input>
          </el-form-item>
          <el-form-item label='找寻款式数' class='form-item-all-line'>
            <el-input v-model='formData.style_num' type='textarea' :rows='2'
                      placeholder='不超过5款' maxlength='500' show-word-limit
                      clearable></el-input>
          </el-form-item>
          <el-form-item label='参考图片1'>
            <ml-upload-image :key='key' :id.sync='formData.refer_cover_1' :url.sync='formData.refer_cover_url_1'/>
          </el-form-item>
          <el-form-item label='参考图片2'>
            <ml-upload-image :key='key' :id.sync='formData.refer_cover_2' :url.sync='formData.refer_cover_url_2'/>
          </el-form-item>
          <el-form-item label='参考图片3'>
            <ml-upload-image :key='key' :id.sync='formData.refer_cover_3' :url.sync='formData.refer_cover_url_3'/>
          </el-form-item>
          <el-form-item style='width:600px;text-align: center'>
            <el-button type='primary' @click.stop.native='saveDemand'>保存</el-button>
            <el-button @click='open=false'>取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>

    <ImportLbDemand :id='lbId' :visible.sync='openImport' @imported='handleQuery'/>
  </div>
</template>

<script>
import ExportBtn from '@/components/export/ExportBtn'
import { mapGetters } from 'vuex'
import ImportLbDemand from '@/pages/lb/dress/dressProduct/ImportLbDemand'
import EditableCell from '@/components/EditableCell'
import MlUploadImage from '@/components/MlUploadImg/single.vue'
import CategoryCascader from '@/pages/category/components/CategoryCascader'


const ModelForm = {
  lb_id: null,
  demand: null,
  price: null,
  store_link: null,
  style_num: null,
  refer_cover_1: null,
  refer_cover_2: null,
  refer_cover_3: null,
  refer_cover_url_1: null,
  refer_cover_url_2: null,
  refer_cover_url_3: null

}
const getKey = function() {
  return new Date().getTime() + ''
}
export default {
  name: 'DemandItemList',
  components: { MlUploadImage, EditableCell, ImportLbDemand, ExportBtn, CategoryCascader },
  computed: {
    ...mapGetters(['userPermissions', 'userInfo']),
    cascaderProps() {
      return { value: 'id', label: 'display_name' }
    }

  },
  props: {
    lbId: {
      type: [Number, String],
      default() {
        return null
      }
    }
  },
  watch: {
    lbId: {
      immediate: true,
      handler() {
        this.getList()
      }
    },
    'formData.pic_url'() {
      this.key = getKey()
    }
  },
  data() {
    return {
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      loadingStatus: false,
      searchCondition: {
        lb_id: null,
        //是否反馈
        is_feed: '',
        //招商对接人
        picker: null,
        demand: null
      },
      isFeedMap: [
        { label: '已反馈', val: 'Y' },
        { label: '未反馈', val: 'N' }
      ],
      total: 0,
      pagingData: {
        page_size: 10,
        current_page: 1
      },
      dataList: [],
      orderSort: { id: 'asc' },
      formData: { ...{}, ...ModelForm },
      dialogTitle: '添加需求',
      open: false,
      openImport: false,
      autosize: { minRows: 5, maxRows: 10 },
      key: getKey(),
      selectedList: [],
      categoryList: []
    }
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    async editCellVal(row, field) {
      if (row.id && field) {
        await this.$api.cellSaveLbDressScheduleDemandItemInfo({ id: row.id, field: field, val: row[field] })
      } else {
        this.$notify.warning('参数错误，无法提交！！！')
      }
    },
    saveDemand() {
      // if (!this.formData.category_id) {
      //   this.$message.error('请选择类目')
      //   return false
      // }
      // if (this.formData.category_name !== '秒杀' && !this.formData.demand) {
      //   this.$message.error('需求必填，若有指定产品直接填写产品名，若无，请填写具体功效需求')
      //   return false
      // }
      // if (this.formData.category_name === '秒杀' && !this.formData.pic_url) {
      //   this.$message.error('秒杀必须上传图片')
      //   return false
      // }
      this.formSubmit()
    },
    async formSubmit() {
      const params = { ...{}, ...this.formData }
      params.lb_id = this.lbId
      let info = await this.$api.saveLbDressScheduleDemandItem(params)
      if (info) {
        this.open = false
        await this.getList()
      }
    },
    reset() {
      this.formData = { ...{}, ...ModelForm }
    },
    handleAdd() {
      this.reset()
      // this.formData.lb_id = this.lbId
      this.dialogTitle = '添加选品需求明细'
      this.open = true
    },
    handleImport() {
      this.openImport = true
    },
    handleCopy(row) {
      delete row.id
      delete row.is_feed
      delete row.feedback
      delete row.picker
      delete row.created_at
      delete row.updated_at
      this.formData = { ...row }
      this.dialogTitle = '添加选品需求明细'
      this.open = true
    },
    handleEdit(row) {
      this.dialogTitle = '修改选品需求明细'
      row.category_ids = []
      if (row.category_id) {
        row.category_ids = [row.category_id]
        if (row.category_2_id) {
          row.category_ids = [row.category_id, row.category_2_id]
        }
      }

      this.formData = { ...row }
      this.open = true
    },
    handleDelete(row) {
      if (row.id) {
        // console.log(row)
        this.$confirm(`确认删除需求【${row.demand || '-'}】?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          let data = await this.$api.delLbDressScheduleDemandItem(row.id)
          if (data) {
            await this.getList()
          }
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
      } else
        this.$notify.error('删除对象ID为空')
    },
    handleSearchCondition() {
      let condition = {}
      //排期条件必传
      condition['lb_id'] = this.lbId
      Object.keys(this.searchCondition).forEach((field) => {
        if (this.searchCondition[field]) {
          condition[field] = this.searchCondition[field]
        }
      })
      return condition
    },
    handleQuery() {
      this.pagingData.current_page = 1
      this.getList()
    },
    async getList() {
      this.loadingStatus = true
      let searchCondition = this.handleSearchCondition()
      // 合并
      Object.assign(searchCondition, this.pagingData, { order: this.orderSort })
      let { list, pages } = await this.$api.getLbDressScheduleDemandItemList(searchCondition)
      this.$nextTick(() => {
        this.dataList = list
        this.total = pages.total
        this.offset = pages.offset
        this.pagingData.current_page = pages.current_page
        this.pagingData.page_size = pages.page_size
        this.loadingStatus = false
      })
    },
    async exportDemandExcel(refExportLink) {
      try {
        let searchCondition = this.handleSearchCondition()
        let name = `红人服饰选品需求明细表`
        let response = await this.$api.exportLbDressScheduleDemandItem(searchCondition)
        if (this.$refs[refExportLink])
          this.$refs[refExportLink].exportSaveXlsx(response, name)
      } catch (e) {
        this.$message.warning('导出异常，请联系管理员')
      }
    },
    handleSelectRows(selection) {
      // this.selectedList = selection || []
      this.ids = selection.map(item => item.id)
      this.single = selection.length !== 1
      this.multiple = !selection.length
    },
    handleBatchDel() {
      this.$confirm(`确认删除【${this.ids.length}条需求数据】?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let data = await this.$api.delBatchLbDressScheduleDemandItem(this.ids)
        if (data) {
          await this.getList()
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
  },
  mounted() {
  }
}
</script>

<style scoped>
.demand {
  font-size: 16px;
  font-weight: bold;
}
</style>
<style lang='scss'>
.demand-form {
  display: grid;
  grid-template-columns: repeat(3, calc(33.3% - 10px));
  grid-column-gap: 20px;

  .form-item-all-line {
    grid-column-start: 1;
    grid-column-end: 4;
  }

  .ml-upload-image {
    width: 110px;
    height: 110px;
  }
}

.image-slot {
  height: 100%;
  width: 100%;

  background-color: #f5f7fa;
  color: #c0c4cc;
}
</style>
